<template>
  <v-card>
    <v-card-title class="headline grey lighten-2">
      プロフィールを変更する
    </v-card-title>

    <v-card-text>
      <v-text-field outlined label="名前" class="mt-4" v-model="name"></v-text-field>
    </v-card-text>

    <v-card-text class="pb-0"> 学習中の資格 : {{ learnLabel }} </v-card-text>

    <v-card-actions>
      <v-btn text　@click="show = !show"> 学習中の資格を変更する </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <v-card-text v-show="show">
        <v-divider></v-divider>
        <v-radio-group v-model="radioValue">
          <v-radio v-for="data in radioList" :key="data.value" :label="data.label" :value="data.value" @change="changeStudyQualification(data.label)"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-expand-transition>

    <v-card-text class="pb-0">取得済の資格を選択する</v-card-text>
    <v-card-actions>
      <v-container fluid class="pt-0">
        <div v-for="data in checkList" :key="data.value">
          <v-checkbox color="primary" v-model="checkValue" :label="data.label" :value="data.value" class="mt-1"></v-checkbox>
        </div>
      </v-container>
    </v-card-actions>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeDialog"> 閉じる </v-btn>
      <v-btn color="primary" text @click="updateProfile"> 更新する </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import define from '../Define'
  import firebase from '../lib/firebase'
  import db from '../lib/db'
  import util from '../utils/util'
  import { mapActions } from 'vuex'
  export default {
    data: function() {
      return {
        radioValue: '',
        learnLabel: '',
        checkValue: [],
        radioList: define.CERTIFICATION_LIST,
        checkList: define.CERTIFICATION_LIST,
        radioListShow: false,
        checkListShow: false,
        show: false,
        name: '',
      }
    },
    computed: {
      uid() {
        return this.$store.getters['user/uid']
      },
    },
    async created() {
      const uid = this.$store.getters['user/uid']
      if (uid) {
        const user = await db
          .collection('user')
          .where('uid', '==', uid)
          .limit(1)
          .get()
          .then((snapShot) => {
            snapShot.forEach(async (doc) => {
              const data = doc.data()
              this.radioValue = data.learning
              this.checkValue = data.getted
              this.name = data.name
              this.learnLabel = util.getCertificationLebel(data.learning)
            })
          })
      }
    },
    methods: {
      ...mapActions('user', ['signIn', 'signOut', 'checkAuth', 'setDisplayName', 'setLearn']),
      ...mapActions('views', ['setSnack']),
      closeDialog() {
        this.$emit('closeDialog')
      },
      /**
       * ラジオボタンで選択した資格にラベルを変更する
       */
      changeStudyQualification(label) {
        this.learnLabel = label
      },
      async updateProfile() {
        if (this.uid) {
          const user = await db
            .collection('user')
            .where('uid', '==', this.uid)
            .limit(1)
            .get()
            .then((snapShot) => {
              snapShot.forEach(async (doc) => {
                const data = doc.data()
                const id = doc.id
                const nowDatetime = util.getNowTime()
                await db
                  .collection('user')
                  .doc(id)
                  .update({
                    name: this.name,
                    learning: this.radioValue,
                    getted: this.checkValue,
                    updated_at: nowDatetime,
                  })
                  .then(() => {
                    this.setDisplayName(this.name)
                    this.setLearn(this.radioValue)
                    this.setSnack(define.MESSAGE_PROFILE_UPDATE_SUCCESS)
                    this.$emit('closeDialog')
                  })
                  .catch(() => {
                    this.setSnack(define.MESSAGE_PROFILE_UPDATE_FAILED)
                    this.$emit('closeDialog')
                  })
              })
            })
        } else {
          this.$emit('closeDialog')
        }
      },
    },
  }
</script>
