<template>
  <v-sheet class="profile">
    <div class="profile_area">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-row justify="center" class="edit_button">
            <v-btn v-bind="attrs" v-on="on" class="mx-auto my-6" color="primary" dark><v-icon left dark> mdi-pencil </v-icon>プロフィールを変更する</v-btn>
          </v-row>
        </template>
        <UpdateProfile @closeDialog="closeProfileDialog" />
      </v-dialog>
    </div>
    <v-card>
      <v-card-title>
        <v-list>
          <v-list-item>
            <v-avatar size="56" color="primary"><img alt="Avatar" :src="this.imageURL"/></v-avatar>
            <v-list-item-title class="ml-3">{{ name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card-title>
      <v-card-text>
        <div class="font-weight-bold mb-1 mt-6">挑戦中の資格</div>
        <div v-show="this.learning == ''">
          まだ設定していません。挑戦してみたい資格が決まったら「プロフィールを変更する」ボタンから選んでみてください。
        </div>
        <div v-show="this.learning != ''">
          <v-list>
            <v-list-item class="mb-1">
              <v-avatar :color="getColor(this.learning)">
                <span class="white--text">{{ this.learning }}</span>
              </v-avatar>
              <v-list-item-title class="ml-3">{{ getName(this.learning) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div class="font-weight-bold mb-1 mt-6">取得済の資格</div>
        <div v-show="this.getted.length == 0">
          まだ資格を設定していません。または取得のために日々前に進んでいます！
        </div>
        <div v-show="this.getted.length != 0">
          <v-list>
            <v-list-item v-for="(gData, index) of getted" :key="index" class="mb-1">
              <v-avatar :color="getColor(gData)">
                <span class="white--text">
                  {{ gData }}
                </span>
              </v-avatar>
              <v-list-item-title class="ml-3">{{ getName(gData) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
        <div class="font-weight-bold mb-1 mt-6">総学習時間</div>
        <div v-show="this.learnList.length == 0">
          まだ学習の記録がありません。Home画面から今日の頑張りを登録してみませんか？
        </div>
        <v-list>
          <v-list-item v-for="data in learnList" :key="data.value" class="mb-1">
            <v-avatar :color="data.color">
              <span class="white--text">{{ data.value }}</span>
            </v-avatar>
            <v-list-item-title class="ml-3">{{ data.time }}時間</v-list-item-title>
          </v-list-item>
        </v-list>
        <div class="font-weight-bold mb-1 mt-6">学習時間履歴(最新30件)</div>
        <div v-show="this.list.length == 0">まだ登録がありません。</div>
        <div v-for="cardData in list" :key="cardData.id">
          <CardItem :cardData="cardData" />
        </div>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
  import CardItem from '../components/CardItem'
  import UpdateProfile from '../components/UpdateProfile'
  import util from '../utils/util'
  import firebase from '../lib/firebase'
  import db from '../lib/db'
  import define from '../Define'
  export default {
    name: 'Profile',

    data: () => {
      return {
        drawer: null,
        dialog: false,
        name: '',
        learning: '',
        getted: [],
        imageURL: '',
        list: [],
        learnList: [],
      }
    },
    components: {
      CardItem,
      UpdateProfile,
    },
    async created() {
      const uid = this.$store.getters['user/uid']
      if (uid) {
        const user = await db
          .collection('user')
          .where('uid', '==', uid)
          .limit(1)
          .get()
          .then((snapShot) => {
            snapShot.forEach(async (doc) => {
              const data = doc.data()
              this.learning = data.learning
              this.getted = data.getted
              this.name = data.name
            })
          })
        let likeList = []
        await db
          .collection('learn')
          .where('uid', '==', uid)
          .orderBy('updated_at', 'desc')
          .limit(30)
          .get()
          .then((snapShot) => {
            let size
            snapShot.forEach(async (doc) => {
              await db
                .collection('learn')
                .doc(doc.id)
                .collection('likedUsers')
                .get()
                .then((snapshotLike) => {
                  likeList = []
                  snapshotLike.forEach((docLike) => {
                    likeList.push({ ...docLike.data() })
                  })
                  size = snapshotLike.size
                })
              let data = {
                id: doc.id,
                size: size,
                ...doc.data(),
                likeList: likeList,
              }
              this.list.push(data)
            })
          })
        let result = define.CERTIFICATION_LIST
        let listData = []
        await result.forEach(async (data) => {
          let time = 0
          await db
            .collection('learn')
            .where('uid', '==', uid)
            .where('certification', '==', data.value)
            .get()
            .then((snapShot) => {
              snapShot.forEach(async (doc) => {
                let obj = { ...doc.data() }
                time += parseFloat(obj.time)
              })
            })
          data.time = time
          if (data.time != 0) {
            this.learnList.push(data)
          }
        })
      }
      this.imageURL = this.$store.getters['user/photoURL']
    },
    methods: {
      async closeProfileDialog() {
        this.dialog = false

        // 以下createと同じ処理なので、後ほど冗長を排除する
        const uid = this.$store.getters['user/uid']
        if (uid) {
          const user = await db
            .collection('user')
            .where('uid', '==', uid)
            .limit(1)
            .get()
            .then((snapShot) => {
              snapShot.forEach(async (doc) => {
                const data = doc.data()
                this.learning = data.learning
                this.getted = data.getted
                this.name = data.name
              })
            })
          let likeList = []
          this.list = []
          await db
            .collection('learn')
            .where('uid', '==', uid)
            .orderBy('updated_at', 'desc')
            .limit(30)
            .get()
            .then((snapShot) => {
              let size
              snapShot.forEach(async (doc) => {
                await db
                  .collection('learn')
                  .doc(doc.id)
                  .collection('likedUsers')
                  .get()
                  .then((snapshotLike) => {
                    likeList = []
                    snapshotLike.forEach((docLike) => {
                      likeList.push({ ...docLike.data() })
                    })
                    size = snapshotLike.size
                  })
                let data = {
                  id: doc.id,
                  size: size,
                  ...doc.data(),
                  likeList: likeList,
                }
                this.list.push(data)
              })
            })
        }
      },
      getColor(value) {
        return util.getCertificationColor(value)
      },
      getName(value) {
        return util.getCertificationLebel(value)
      },
    },
  }
</script>
<style>
  .profile {
    background-color: #f5f5f5 !important;
  }
  .profile_area {
    height: 84px;
  }
  .edit_button {
    width: 100%;
    margin: 0;
  }
</style>
